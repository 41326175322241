body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;
}

.header-a {
  padding-top: 20px;
  padding-bottom: 20px;
}

.alink {
  /* background-color: red; */
  font-size: 14px;
  font-weight: 600;
}

.alink {
  color: black;
}

.nav-link {
  color: black !important;
}

.alink ul {
  float: left;
}

.nav-container .start {
  font-weight: 600;
  color: black;
}


.navbar-nav>li {
  padding-left: 35px;
  padding-right: 35px;
}

.start {
  border-radius: 9px;
  border-width: 0.1em;
  font-size: 15px;
  padding: 10px 20px 10px 20px;
  margin-left: 30px;
}

.start:hover {
  color: white;
}

.brand {
  font-weight: bolder;
}